<template>
  <div class="navbar-container">
    <nav class="header navbar navbar-expand-lg bg-dark navbar-light">
      <div class="d-flex justify-content-md-between align-items-center container">
        <div class="d-none d-sm-block">
          <img class="navbar-item leica" src="./assets/leica-geosystems.svg" />
        </div>
        <div class="">
          <router-link to="/" class="navbar-item">
            <img class="s4y-logo" src="./assets/scanner4you_logo.svg" />
          </router-link>
        </div>
        <div class="justify-content-end">
          <div class="buttons">
            <template v-if="$store.state.isAuthenticated">
              <router-link to="/account" class="menu-item-icon">
                <button class="v-icon">
                  <i class="icon-profile"></i>
                </button>
              </router-link>
            </template>
            <template v-else>
              <router-link to="/log-in" class="menu-item-icon is-light ">
                <button class="v-icon">
                  <i class="icon-profile"></i></button>
              </router-link>
            </template>
            <router-link to="/cart" class="menu-item-icon button is-success">
              <button class="v-icon">
                <span class="cart-icon-counter">{{ cartCount }}</span>
                <i class="icon-cart"></i>
              </button>
            </router-link>
            <a href="tel:+41 71 311 44 00" class="d-none d-sm-inline menu-item-icon">
              <button class="v-icon">
                <i class="icon-phone"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
    </nav>
    <nav class="main-navigation">
      <div class="container">
        <div class="navbar-menu" id="navbar-menu" v-bind:class="{ 'is-active': showMobileMenu }">
          <div class="navbar-container">
            <nav class="navbar menu">
              <ul class="nav justify-content-center">
                <li class="nav-item">
                  <router-link to="/mieten" class="nav-link">Mieten</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/kaufen" class="nav-link">Kaufen</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/schulen" class="nav-link">Schulen</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/ueber-uns" class="nav-link">Über uns</router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div id="main-container">
      <router-view />
  </div>
  <div class="cta">
    <div class="container">
      <div class="row">
        <div class="col-12 justify-content-center text-center">
          <h2 class="support-title">Wir sind für Sie da!</h2>
          <div class="support-subline">Wir haben Antworten zum Thema Laserscanning</div>

        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3 mb-5 mb-md-0">
          <a class="support-option" href="mailto:info@scanner4you.ch">
            <i class="fs-1 bi bi-envelope-at"></i>
            <div class="support-option-title">E-Mail</div>
            <div class="support-option-detail">Rund um die Uhr</div>
          </a>
        </div>
        <div class="col-6 col-md-3 mb-5 mb-md-0">
          <a class="support-option" href="tel:071 311 44 00">
            <i class="fs-1 bi bi-telephone"></i>
            <div class="support-option-title">Telefon</div>
            <div class="support-option-detail">Mo-Fr, 8-17 Uhr</div>
          </a>
        </div>
        <div class="col-6 col-md-3 mb-md-0">
          <a class="support-option" href="https://wa.me/message/BGK37QCJWBXAO1" target="_blank">
            <i class="fs-1 bi bi-whatsapp"></i>
            <div class="support-option-title">WhatsApp</div>
            <div class="support-option-detail">Mo-Fr, 8-17 Uhr</div>
          </a>
        </div>
        <div class="col-6 col-md-3 mb-md-0">
          <a class="support-option" href="https://outlook.office365.com/book/scanner4you1@vermflex.ch/" target="_blank">
            <i class="fs-1 bi bi-calendar3"></i>
            <div class="support-option-title">Online</div>
            <div class="support-option-detail">Termin vereinbaren</div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer text-light">
    <div class="container">
      <div class="row">
        <div class="col-6 col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0">
          <div class="footer-title">scanner4you</div>
          <div class="footer-text">
            by <a href="https://vermflex.ch/" target="_blank">Vermflex</a><br />
            Bionstrasse 3<br />
            CH-9015 St.Gallen<br />
            <a href="mailto:info@scanner4you.ch" class="">info@scanner4you.ch</a><br />
            <a href="tel:+41 71 311 44 00" class="">071 311 44 00</a>
          </div>
        </div>
        <div class="col-6 col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0">
          <div class="footer-title">Über uns</div>
          <ul class="nav flex-column">
            <li class="footer-nav-item">
              <router-link to="/ueberuns" class="nav-link">scanner4you</router-link>
            </li>
            <li class="footer-nav-item">
              <a href="/agb" class="nav-link">AGB</a>
            </li>
            <li class="footer-nav-item">
              <a href="/datenschutz" class="nav-link">Datenschutz</a>
            </li>
            <li class="footer-nav-item">
              <a href="/impressum" class="nav-link">Impressum</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0">
          <div class="footer-title">Geräte</div>
          <ul class="nav flex-column">
            <li class="footer-nav-item">
              <a href="/kaufen/blk-2-go" class="nav-link">BLK 2GO</a>
            </li>
            <li class="footer-nav-item">
              <a href="/kaufen/blk-360-g2" class="nav-link">BLK 360 G2</a>
            </li>
            <li class="footer-nav-item">
              <a href="/kaufen/blk-3d" class="nav-link">BLK 3D</a>
            </li>
            <li class="footer-nav-item">
              <a href="/kaufen/rtc-360" class="nav-link">RTC 360</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0">
          <div class="footer-title">Angebot</div>
          <ul class="nav flex-column">
            <li class="footer-nav-item">
              <a href="/mieten" class="nav-link">Mieten</a>
            </li>
            <li class="footer-nav-item">
              <a href="/kaufen" class="nav-link">Kaufen</a>
            </li>
            <li class="footer-nav-item">
              <a href="/schulen" class="nav-link">Schulen</a>
            </li>
            <li class="footer-nav-item">
              <a href="/kaufen/zubehoer" class="nav-link">Zubehör</a>
            </li>
            <li class="footer-nav-item">
              <a href="/kaufen/software-service" class="nav-link">Software & Services</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex flex-column flex-sm-row pt-4">
        <ul class="list-unstyled d-flex">
          <li class=""><a href="https://www.linkedin.com/company/scanner4you/" target="_blank"><i
                class="bi bi-linkedin"></i></a></li>
          <li class="ms-3"><a href="https://www.facebook.com/profile.php?id=100089992097563"><i
                class="bi bi-facebook"></i></a></li>
          <li class="ms-3"><a href="https://www.instagram.com/scanner4you/"><i class="bi bi-instagram"></i></a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>


<script>
import axios from "axios";
export default {
  data() {
    return {
      showMobileMenu: false,
    };
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
    this.$store.commit("updateCartCount");
  },
  computed: {
    cartCount() {
      return this.$store.state.cartCount;
    }
  }
};
</script>

<style>
@import "./assets/styles/main.scss";
</style>
